// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
//@import '~bootstrap/scss/bootstrap';

@import "css/datatables.bundle.css";
@import "mixins";
@import "custom";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";
