.hidden {
    display: none;
}


.gebruikers_show {
    .card-body {
        ul {
            list-style: none;
        }
    }
}

body {
    &.fader {
        opacity: 0;
        transition: .2s ease;
    }

    &.faded {
        transition: .2s ease;
        opacity: 1;
    }
}

.opdrachtgever_show, .leverancier_show {
    .card-body {
        ul {
            list-style: none;
        }

        img.logo {
            width: 100%;

        }
    }

}

#leverancier_logo_preview {
    width: 100%;
}

.artikel_show {
    .card-body {
        ul {
            list-style: none;
        }

        img.image {
            width: 100%;

        }
    }

}

.header-mobile {
    background-color: #ffffff;

    .mobile-logo {
        img {
            height: auto;
            width: 75px;
        }
    }
}

.brand {
    background-color: #ffffff;
}

.brand-logo {
    margin: 0 auto;

    img {
        width: auto;
        height: 50px;
    }
}

.image-input {

    background-position: center;
    background-size: contain;

    .image-input-wrapper {
        width: 200px;
        height: 200px;
    }
}

.b-0 {
    bottom: 0;
    position: absolute;
}

.symbol-label {
    img {

        width: 35px;
        height: 35px;
        border-radius: 0.42rem;
    }
}

.opdrachtgever-logo-preview {

    width: 100%;
    height: auto;
}

.vragenlijst_bg_preview {

    width: 100%;
    height: auto;
}

.keukenopstelling-image-preview {

    width: 100%;
    height: auto;
}

.avatar-delete {
    right: -20px !important;
}

.avatar-add {
    right: 5px !important;
}

.login-bg {
    @include md() {
        order: 1;
    }
}

div.login-wrapper {
    @include md() {

        margin-top: 1rem !important;
    }

    .login-form {
        img {
            @include md() {

                max-height: 90px !important;
            }

        }
    }
}

#hoofdcategorie_dt tr {
    cursor: pointer;
}

//.table_actions {
//    a {
//        width: auto !important;
//    }
//}

.wizard [data-wizard-type=action-import] {
    display: none;
}

///vragenlijst
.vragenlijst {
    .text-muted {
        color: #696969 !important;
    }
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside {
    background-color: #ffffff;
    box-shadow: 0px 0px 40px rgba(177, 187, 208, 0.15);
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav {
    padding: 0;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step {
    padding: 0.75rem 0;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    margin-bottom: 1.5rem;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step:last-child {
    margin-bottom: 0;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
    display: flex;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step .wizard-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #F3F6F9;
    margin-right: 1.4rem;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step .wizard-icon .wizard-check {
    display: none;
    font-size: 1.4rem;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step .wizard-icon .wizard-number {
    font-weight: 600;
    color: #3F4254;
    font-size: 1.35rem;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step .wizard-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
    color: #181C32;
    font-weight: 500;
    font-size: 1.4rem;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-desc {
    color: #B5B5C3;
    font-size: 1.08rem;
    font-weight: 500;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-icon {
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    background-color: #C9F7F5;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-icon .wizard-check {
    color: #1BC5BD;
    display: inline-block;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-icon .wizard-number {
    display: none;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-title {
    color: #B5B5C3;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-desc {
    color: #D1D3E0;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] {
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon {
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    background-color: #C9F7F5;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon .wizard-check {
    color: #1BC5BD;
    display: none;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon .wizard-number {
    color: #1BC5BD;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-title {
    color: #181C32;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-desc {
    color: #B5B5C3;
}

.vragenlijst.vragenlijst-3 .vragenlijst-aside .aside-img-wizard {
    min-height: 320px !important;
    background-size: 400px;
}

.vragenlijst.vragenlijst-3 .vragenlijst-content {
    background-color: #F3F5F9;
}

.vragenlijst.vragenlijst-3 .vragenlijst-content .form-group .fv-help-block {
    font-size: 1.1rem !important;
    padding-top: 3px;
}

.vragenlijst-aside {
    .wizard-desc {
        color: #696969;
    }
}

@media (min-width: 992px) {
    .vragenlijst.vragenlijst-3 .vragenlijst-aside {
        width: 100%;
        max-width: 600px;
    }
    .vragenlijst.vragenlijst-3 .vragenlijst-aside .aside-img {
        min-height: 550px !important;
        background-size: 630px;
    }
    .vragenlijst.vragenlijst-3 .vragenlijst-content .top-signup {
        max-width: 650px;
        width: 100%;
    }
    .vragenlijst.vragenlijst-3 .vragenlijst-content .top-signin {
        max-width: 450px;
        width: 100%;
    }
    .vragenlijst.vragenlijst-3 .vragenlijst-content .top-forgot {
        max-width: 450px;
        width: 100%;
    }
    .vragenlijst.vragenlijst-3 .vragenlijst-content .vragenlijst-form {
        width: 100%;
        max-width: 450px;
    }
    .vragenlijst.vragenlijst-3 .vragenlijst-content .vragenlijst-form.vragenlijst-form-signup {
        max-width: 750px;
    }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
    .vragenlijst.vragenlijst-3 .vragenlijst-aside {
        width: 100%;
        max-width: 400px;
    }
}

@media (max-width: 991.98px) {
    .vragenlijst.vragenlijst-3 .vragenlijst-aside .aside-img {
        min-height: 500px !important;
        background-size: 500px;
    }
    .vragenlijst.vragenlijst-3 .vragenlijst-aside .vragenlijst-logo {
        text-align: center;
    }
    .vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav {
        padding: 0;
        align-content: center;
    }
    .vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step {
        margin-bottom: 0.5rem;
    }
    .vragenlijst.vragenlijst-3 .vragenlijst-aside .wizard-nav .wizard-steps .wizard-step:last-child {
        margin-bottom: 0;
    }
    .vragenlijst.vragenlijst-3 .vragenlijst-content .top-signup {
        width: 100%;
        max-width: 400px;
    }
    .vragenlijst.vragenlijst-3 .vragenlijst-content .top-signin {
        max-width: 400px;
        width: 100%;
    }
    .vragenlijst.vragenlijst-3 .vragenlijst-content .top-forgot {
        max-width: 400px;
        width: 100%;
    }
    .vragenlijst.vragenlijst-3 .vragenlijst-content .vragenlijst-form {
        width: 100%;
        max-width: 400px;
    }
}

@media (max-width: 575.98px) {
    .vragenlijst.vragenlijst-3 .vragenlijst-aside .aside-img {
        min-height: 300px !important;
        background-size: 350px;
    }
}

.vragenlijst-content {
    [data-wizard-type=action-next], [data-wizard-type=action-submit], [data-wizard-type=action-prev] {
        display: inline-block !important;
    }
}

body.vragenlijst-index, body.vragenlijst-show, body.vragenlijst-edit {
    //overflow: hidden;
    //
    //.vragenlijst-form.vragenlijst-form-signup {
    //    height: 100vh;
    //}
    //.vragenlijst-content {
    //    overflow: scroll;
    //    height: 103vh;
    //}
}

@media (max-width: 991px) {

    //body.vragenlijst-index, body.vragenlijst-show, body.vragenlijst-edit {
    //    overflow: inherit;
    //    .vragenlijst-content {
    //        overflow: scroll;
    //        height: 102vh;
    //    }
    //    .vragenlijst-aside {
    //        padding-bottom: 25px;
    //    }
    //}
}

//end vragenlijst

.custom-control.image-checkbox {
    position: relative;

    border: 1px solid #e1e1e1;
    //min-height: 90%;
    min-height: 190px;
    padding: 5px;

    @media (max-width: 991px) {
        border: none;

    }
    //.artikel-image-wrapper {
    //    padding-top: 100%;
    //    width: 100%;
    //    position: relative;
    //    object-fit: contain;
    //    object-position: center;
    //}
    //
    //img {
    //    border-radius: 2.5px;
    //    position: absolute;
    //    top: 0;
    //    height: 100%;
    //    width: 100%;
    //}
    .image {
        position: relative;

        &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: 100%;
        }

        > .content {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }

    &.bekijken-image-checkbox {
        min-height: 80%;
    }

    .custom-control-label {

        display: block;
    }

    .custom-control-input:checked ~ .custom-control-label {


        &:after {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+");
            filter: invert(1);

            background-position: 5px 5px;
            height: 25px;
            width: 25px;
            z-index: 2;
        }

        &:after,
        &:before {
            opacity: 1;
        }

        &:before {
            width: 25px;
            height: 25px;
        }
    }

    label {
        cursor: pointer;

        &:before {
            z-index: 1;
            border-color: #007bff;
            background-color: #007bff;
        }


        &:after,
        &:before {
            transition: opacity .3s ease;
            opacity: 0;
            left: .25rem;
        }

        &:focus,
        &:hover {
            opacity: .8;
        }

    }
}

.form-group-image-checkbox.is-invalid {
    label {
        color: #000;
    }

    .invalid-feedback {
        display: block;
    }
}

.vragenlijst-content {
    //background-image: url('https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/bg/bg-1.jpg');
    background-image: url('https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/bg/bg-9.jpg');
    background-repeat: no-repeat;
    background-size: cover;

}

.vragenlijst-woonconsulent-info {
    p {
        font-size: 16px;

    }

    .vragenlijst-woonconsulent-avatar {

        width: 100%;
        border-radius: 5px;
        object-fit: cover;
    }
}

.keuken_opstelling_view {
    width: 100%;
    object-fit: contain;
}

.image-flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

#projecten_table {
    span.raw {
        display: none;
    }
}


//progress circle

.progress-circle {
    position: relative;
    display: inline-block;
    margin: 1rem;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #ebebeb;
}

.progress-circle:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-animation: colorload 2s;
    animation: colorload 2s;
}

.progress-circle span {
    //font-size: 1rem;
    color: #8b8b8b;
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 90px;
    height: 90px;
    line-height: 60px;
    margin-left: -45px;
    margin-top: -45px;
    text-align: center;
    border-radius: 50%;
    background: #fff;
    padding: 15px;
    font-size: 20px;
    z-index: 1;
}

.progress-circle span:after {
    content: "%";
    font-weight: 600;
    color: #8b8b8b;
}

.progress-circle.progress-0:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(90deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-1:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(93.6deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-2:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(97.2deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-3:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(100.8deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-4:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(104.4deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-5:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(108deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-6:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(111.6deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-7:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(115.2deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-8:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(118.8deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-9:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(122.4deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-10:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(126deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-11:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(129.6deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-12:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(133.2deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-13:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(136.8deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-14:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(140.4deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-15:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(129.6deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-16:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(133.2deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-17:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(136.8deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-18:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(140.4deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-19:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(144deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-20:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(151.2deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-21:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(158.6deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-22:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(161.2deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-23:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(165.6deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-24:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(169.2deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-25:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(180deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-26:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(183.6deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-27:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(187.2deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-28:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(190.8deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-29:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(194.4deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-30:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(198deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-31:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(201.6deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-32:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(205.2deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-33:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(208.8deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-34:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(212.4deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-35:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(216deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-40:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(234deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-36:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(219.6deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-37:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(223.2deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-38:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(226.8deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-39:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(230.4deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-41:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(237.6deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-42:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(241.2deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-43:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(244.8deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-44:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(248.4deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-45:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(252deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-46:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(255.6deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-47:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(259.2deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-48:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(262.8deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-49:after {
    background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(266.4deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-50:after {
    background-image: linear-gradient(-90deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-51:after {
    background-image: linear-gradient(-86.4deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-52:after {
    background-image: linear-gradient(-82.6deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-53:after {
    background-image: linear-gradient(-79.2deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-54:after {
    background-image: linear-gradient(-75.6deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-55:after {
    background-image: linear-gradient(-72deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-56:after {
    background-image: linear-gradient(-68.4deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-57:after {
    background-image: linear-gradient(-64.8deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-58:after {
    background-image: linear-gradient(-61.2deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-59:after {
    background-image: linear-gradient(-57.6deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-60:after {
    background-image: linear-gradient(-54deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-61:after {
    background-image: linear-gradient(-50.4deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-62:after {
    background-image: linear-gradient(-46.8deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-63:after {
    background-image: linear-gradient(-43.2deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-64:after {
    background-image: linear-gradient(-39.6deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-65:after {
    background-image: linear-gradient(-36deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-66:after {
    background-image: linear-gradient(-32.4deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-67:after {
    background-image: linear-gradient(-28.8deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-68:after {
    background-image: linear-gradient(-25.2deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-69:after {
    background-image: linear-gradient(-21.6deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-70:after {
    background-image: linear-gradient(-18deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-71:after {
    background-image: linear-gradient(-14.4deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-72:after {
    background-image: linear-gradient(-10.8deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-73:after {
    background-image: linear-gradient(-7.2deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-74:after {
    background-image: linear-gradient(-3.6deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-75:after {
    background-image: linear-gradient(0deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-76:after {
    background-image: linear-gradient(3.6deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-77:after {
    background-image: linear-gradient(7.2deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-78:after {
    background-image: linear-gradient(10.8deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-79:after {
    background-image: linear-gradient(14.4deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-80:after {
    background-image: linear-gradient(18deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-81:after {
    background-image: linear-gradient(21.6deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-82:after {
    background-image: linear-gradient(25.2deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-83:after {
    background-image: linear-gradient(28.8deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-84:after {
    background-image: linear-gradient(32.4deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-85:after {
    background-image: linear-gradient(36deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-86:after {
    background-image: linear-gradient(39.2deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-87:after {
    background-image: linear-gradient(43.2deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-88:after {
    background-image: linear-gradient(46.8deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-89:after {
    background-image: linear-gradient(50.4deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-90:after {
    background-image: linear-gradient(54deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-91:after {
    background-image: linear-gradient(57.6deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-92:after {
    background-image: linear-gradient(61.2deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-93:after {
    background-image: linear-gradient(64.8deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-94:after {
    background-image: linear-gradient(68.4deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-95:after {
    background-image: linear-gradient(72deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-96:after {
    background-image: linear-gradient(75.6deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-97:after {
    background-image: linear-gradient(79.2deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-98:after {
    background-image: linear-gradient(82.8deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-99:after {
    background-image: linear-gradient(86.4deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

.progress-circle.progress-100:after {
    background-image: linear-gradient(90deg, #1BC5BD 50%, transparent 50%, transparent), linear-gradient(270deg, #1BC5BD 50%, #ebebeb 50%, #ebebeb);
}

@-webkit-keyframes colorload {
    0% {
        opacity: 0;
        transform: rotate(0deg);
    }
    100% {
        opacity: 1;
        transform: rotate(360deg);
    }
}


section.vragenlijst_create {
    .vragenlijst-create-hoofdcatselect {
        //padding: 5px;
        margin-bottom: 15px;
        //font-size: 1.5em;

        .form-check-label {
            margin-left: 15px;
            background-color: #f3f6f9;
            padding: 15px;
            width: 100%;
            border-radius: 0.5rem;
            transition: .1s ease;

            &:hover {
                cursor: pointer;
                background-color: #d4d5de;
            }
        }

        .form-check-input {
            width: 20px;
            height: 20px;
            margin-top: 20px;
        }
    }

    .edit-subcat-title {
        float: right;
        margin-right: 15px;
        transition: .1s ease;

        &:hover {
            .svg-icon.svg-icon-primary svg g [fill] {
                fill: #e4e6ef !important;
            }
        }
    }

    span[data-sub-select], span[data-main-select] {
        width: 100%;
        display: block;
        height: 100%;
        cursor: pointer;
        background: #f3f6f9;
        padding: 5px 5px 5px 15px;
        border-radius: 4px;
    }

    .save-subcat-title {
        margin-right: 2px;
        background: #ebedf3;
        border-radius: 4px;
        position: relative;
        padding: 1px;
        display: inline-block;
    }

}

.alert[data-notify] .close:before {
    display: none;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-icon.svg-icon svg g [fill], .aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon.svg-icon svg g [fill] {
    fill: #494b74;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-icon.svg-icon svg g [fill], .aside-menu .menu-nav > .menu-item.menu-item-open.menu-item-here > .menu-link .menu-icon.svg-icon svg g [fill] {
    fill: #3699FF;

}

.btn-no-hover {
    .svg-icon svg g [fill] {
        fill: #b5b5c3 !important;
    }
}

span[data-sub-select], span[data-main-select] {
    transition: .3s;
}

span[data-sub-select].active, span[data-main-select].active {
    border: 1px solid #3699ff;
    box-shadow: 0px 0px 10px 0px rgb(54 153 255 / 0.3);
    transition: .3s ease-in;
}

.form .plan input, .form .payment-plan input, .form .payment-type input {
    display: none;
}

.form label.custom-label {
    position: relative;
    color: #aaa;
    //background-color: #aaa;
    font-size: 1rem;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    border: 1px solid #aaa;
    box-sizing: border-box;
    //border-radius: 5px;
    padding: .65rem 1rem;
    line-height: 1.5;
    font-weight: 400;
    border-radius: .42rem;
    width: 75px;

}

.plan input:checked + label, .form .payment-plan input:checked + label, .form .payment-type input:checked + label {
    border: 2px solid #2fcc71;
    background-color: #2fcc71;
    color: #fff;
}

.plan input:checked[value=nee] + label, .form .payment-plan input:checked[value=nee] + label, .form .payment-type input:checked[value=nee] + label {
    border: 2px solid #F44336;
    background-color: #F44336;
    color: #fff;
}

// .plan input:checked + label:after, form .payment-plan input:checked + label:after, .form .payment-type input:checked + label:after{
//    content: "\2713";
//    width: 40px;
//    height: 40px;
//    line-height: 40px;
//    border-radius: 100%;
//    border: 2px solid #333;
//    background-color: #2fcc71;
//    z-index: 999;
//    position: absolute;
//    top: -10px;
//    right: -10px;
//}
//.plan input:checked[value=nee] + label:after, form .payment-plan input:checked[value=nee] + label:after, .form .payment-type input:checked[value=nee] + label:after{
//
//    background-color: #F44336;
//}


.circleloader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #cf343e;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.min-width-actions {
    display: block;
    min-width: 100px;
}

.min-width-extras {
    display: block;
    min-width: 60px;
}

.yes-no-switch {

    .switch.switch-outline.switch-success input:empty ~ span:before {

        border: 2px solid #f76171;
    }

    .switch.switch-outline.switch-success input:checked ~ span:before {
        border: 2px solid #1BC5BD !important;
    }

    .switch.switch-outline.switch-success input:empty ~ span:after {

        background-color: #f64e60;
    }

    .switch.switch-outline.switch-success input:checked ~ span:after {

        background-color: #1BC5BD !important;
    }
}
.btn.btn-icon.btn-sm, .btn-group-sm > .btn.btn-icon {

    width: calc(1.35em + 1.1rem + 2px)!important;
    i.la {
        font-size: 1.3rem!important;
    }
}
.dataTables_wrapper .dataTable th, .dataTables_wrapper .dataTable td {
    padding: 0.5rem 1rem;
}
.modal {
    width: 100%!important;
}

.label-full {
    white-space: nowrap;
    text-align: center;
}

[data-copy-value]:hover, .hover-color:hover {
    cursor: pointer;

    color: #51d1db;
}

[data-copy-value], .hover-color {
    transition: color 0.3s;
}
